import CareerDetail from "../../features/career_detail";

export default (props: Object) => {
  return (
    <CareerDetail
      {...props}
      locales={{
        it: {
          title: "Business developer",
          seoDescription:
            "Essere lofter significa curiosità, ambizione e passione. Lavoriamo per portare innovazione nel mondo del software e dei prodotti digitali. Ti va di unirti a noi?",
          roles: [
            "Insieme al team direttivo strutturerai strategie per raggiungere i clienti in target",
            "Ti interfaccerai con i possibili clienti e gestirai la fase di contrattazione",
            "Lavorerai con il team marketing per realizzare strategie online e offline legate al brand",
            "Darai supporto all’organizzazione di eventi e apertivi che organizziamo a Padova",
            "Cercherai opportunità di crescita e networking per il team (partecipazioni ad eventi, corsi di formazione, convegni)",
            "Tutto quello che pensi sia meglio per far crescere Mabiloft!",
          ],
          prerequisites: [
            "Conoscenza del mondo digital: possiamo insegnarti come funzionano le nostre tecnologie, ma devi avere lo spirito adatto!",
            "Esperienza lavorativa nell’ambito di uno o due anni",
            "Buona conoscenza dell’inglese",
          ],
          yearsOfExperience: 2,
        },
      }}
    />
  );
};
